import React, { useEffect } from 'react';
import Navbar from '../components/Navbar';
import { useTranslation } from "react-i18next";

import '../assets/css/style.min.css';
import '../assets/css/icons.min.css';
import '../assets/libs/aos/aos.css';

function Home() {
    const { t } = useTranslation();
    const language = localStorage.getItem("i18nextLng");
    useEffect(() => {
        const loadScripts = () => {
          const scripts = [
            "/assets/libs/preline/preline.js",
            "/assets/libs/gumshoejs/gumshoe.polyfills.min.js",
            "/assets/libs/lucide/umd/lucide.min.js",
            "/assets/libs/aos/aos.js",
            "/assets/js/theme.js",
          ];
    
          scripts.forEach((src) => {
            const script = document.createElement("script");
            script.src = src;
            script.async = false; // Load in order
            document.body.appendChild(script);
    
            script.onload = () => {
              console.log(`${src} loaded`);
            };
    
            script.onerror = () => {
              console.error(`Failed to load ${src}`);
            };
          });
        };
    
        // Load scripts after the DOM is fully loaded
        if (document.readyState === "complete") {
          loadScripts();
        } else {
          window.addEventListener("load", loadScripts);
        }
    
        // Cleanup function
        return () => {
          window.removeEventListener("load", loadScripts);
        };
      }, []);
    return (
        <div>
            <Navbar />
            <section dir={language == 'ar'? 'rtl' : 'ltr'} id="home" className="relative overflow-hidden pt-40 pb-20" data-aos="zoom-out" data-aos-easing="ease" data-aos-duration="1000">
                <div className="absolute h-14 w-14 bg-primary/10 top-1/2 start-80 -z-1 rounded-2xl rounded-tl-none rounded-br-none animate-[spin_10s_linear_infinite]"></div>
                <div className="absolute h-14 w-14 bg-primary/20 top-1/2 end-80 -z-1 rounded-full animate-ping"></div>
                <div className="container">
                    <div className="text-center">
                        <div className="flex justify-center mt-6">
                            <div className="max-w-2xl">
                                <h1 className="text-5xl/tight text-default-100 font-medium mb-6">{t("AI Solutions Tailored for Your Business and Personal Needs")}</h1>
                                <p className="text-base text-default-300 font-medium lg:max-w-md mx-auto">{t("Empower your business and daily personal tasks with our LLM and Computer Vision tools.")}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            
            {/* AI Solutions Hub Section */}
            <section dir={language == 'ar'? 'rtl' : 'ltr'} id="tools" className="py-20" data-aos="fade-up" data-aos-easing="ease" data-aos-duration="1000">
                <div className="container">
                    <div className="max-w-2xl mx-auto text-center">
                        <h2 className="text-3xl font-medium capitalize text-white mb-4">{t("AI Solutions Hub")}</h2>
                        <p className="text-sm text-default-200 font-medium">{t("Choose between building your own AI agent or using our pre-built AI solutions.")}</p>
                    </div>
                    <div className="grid lg:grid-cols-2 gap-6 mt-10">
                        <div onClick={() => window.location.href='/ai-studio'} className="cursor-pointer bg-default-950/40 rounded-xl backdrop-blur-3xl p-10 text-center hover:bg-default-950/60 transition">
                            <h3 className="text-2xl font-medium text-default-200 mb-4">{t("AI Agent Studio")}</h3>
                            <p className="text-sm text-default-100">{t("Build your own AI Agent by uploading data, training, and generating a custom AI chatbot.")}</p>
                        </div>
                        {/* onClick={() => window.location.href='/public-agents'} */}
                        <div className="cursor-pointer bg-default-950/40 rounded-xl backdrop-blur-3xl p-10 text-center hover:bg-default-950/60 transition">
                            <h3 className="text-2xl font-medium text-default-200 mb-4">{t("Public AI Agents")}</h3>
                            <p className="text-sm text-default-100">{t("Use our ready-to-go AI agents instantly for specialized tasks.")}</p>
                            <p style={{marginTop: 10, fontSize: 16, fontWeight: "bold"}}>{t("Coming Soon")}</p>
                        </div>
                    </div>
                </div>
            </section>

            {/* Why Choose Our AI Solutions Section */}
            <section dir={language == 'ar'? 'rtl' : 'ltr'} id="why-us" className="py-20" data-aos="fade-up" data-aos-easing="ease" data-aos-duration="1000">
                <div className="container text-center">
                    <h2 className="text-3xl font-medium capitalize text-white mb-4">{t("Why Choose Our AI Solutions?")}</h2>
                    <p className="text-sm text-default-200 font-medium mb-10">{t("Discover the key benefits of our AI-driven services designed for businesses of all sizes.")}</p>
                    <div className="grid lg:grid-cols-3 md:grid-cols-2 sm:grid-cols-1 gap-6 max-w-6xl mx-auto">
                        {[
                            { title: "State-of-the-Art AI Models", description: "Leverage the latest AI advancements for high accuracy and performance." },
                            { title: "Customizable & Scalable", description: "Adapt AI solutions for small businesses or large enterprises effortlessly." },
                            { title: "Seamless API Integrations", description: "Easily connect with your existing business tools, CRMs, and platforms." },
                            { title: "Real-Time Insights & Analytics", description: "Track AI usage, customer interactions, and performance with powerful insights." },
                            { title: "Multi-Language & Localization Support", description: "Enable AI to understand and respond in multiple languages for global reach." },
                            { title: "Privacy & Security Focused", description: "Enterprise-grade encryption and compliance with GDPR and data protection laws." },
                        ].map((feature, index) => (
                            <div key={index} className="bg-default-950/40 rounded-xl p-6 backdrop-blur-3xl flex flex-col items-center text-center hover:bg-default-950/60 transition">
                                <h3 className="text-xl font-medium text-default-200 mb-2">{t(feature.title)}</h3>
                                <p className="text-sm text-default-100">{t(feature.description)}</p>
                            </div>
                        ))}
                    </div>
                </div>
            </section>

            {/* Pricing Section */}
            {/* <section dir={language == 'ar'? 'rtl' : 'ltr'} id="pricing" className="py-20" data-aos="fade-up" data-aos-easing="ease" data-aos-duration="1000">
                <div className="container text-center">
                    <h2 className="text-3xl font-medium capitalize text-white mb-4">{t("Pricing Plans")}</h2>
                    <p className="text-sm text-default-200 font-medium mb-10">{t("Select the best plan that fits your needs.")}</p>
                    <div className="grid lg:grid-cols-5 md:grid-cols-4 sm:grid-cols-1 gap-6 max-w-6xl mx-auto">
                        {[
                            { name: "Free", price: "$0/mo", features: ["Basic AI usage", "Limited queries", "Community support"] },
                            { name: "Starter", price: "$19/mo", features: ["Enhanced AI access", "500 queries/mo", "Email support"] },
                            { name: "Standard", price: "$49/mo", features: ["Advanced AI tools", "2000 queries/mo", "Priority support"] },
                            { name: "Pro", price: "$99/mo", features: ["Full AI capabilities", "Unlimited queries", "Dedicated support"] },
                            { name: "Pay-As-You-Go API", price: "$0.01/call", features: ["Only pay for what you use", "No monthly fees", "Bulk discounts available"] },
                        ].map((plan) => (
                            <div key={plan.name} className="bg-default-950/40 rounded-xl p-6 backdrop-blur-3xl flex flex-col items-center text-center hover:bg-default-950/60 transition">
                                <h3 className="text-2xl font-medium text-default-200 mb-2">{t(plan.name)}</h3>
                                <p className="text-3xl font-bold text-primary mb-4">{plan.price}</p>
                                <ul className="text-sm text-default-100 mb-6 space-y-2">
                                    {plan.features.map((feature, index) => (
                                        <li key={index}>{t(feature)}</li>
                                    ))}
                                </ul>
                                {plan.contact ? (
                                    <a href="/contact" className="px-6 py-2 text-white bg-primary rounded-lg text-lg font-medium hover:bg-primary/80">
                                        {t("Contact Us")}
                                    </a>
                                ) : (
                                    <a href="/pricing" className="px-6 py-2 text-white bg-primary rounded-lg text-lg font-medium hover:bg-primary/80">
                                        {t("Select Plan")}
                                    </a>
                                )}
                            </div>
                        ))}
                    </div>
                </div>
            </section> */}

            {/* Enterprise Section */}
            <section dir={language == 'ar'? 'rtl' : 'ltr'} id="enterprise" className="py-20 bg-gray-900 text-center" data-aos="fade-up" data-aos-easing="ease" data-aos-duration="1000">
                <div className="container">
                    <h2 className="text-3xl font-medium capitalize text-white mb-4">{t("Enterprise Solutions")}</h2>
                    <p className="text-sm text-default-200 font-medium mb-10">{t("Get tailored AI solutions with enterprise-grade security and dedicated AI consulting.")}</p>
                    <div className="bg-default-950/40 rounded-xl p-8 backdrop-blur-3xl max-w-3xl mx-auto">
                        <h3 className="text-2xl font-medium text-default-200 mb-2">{t("Custom Enterprise Plan")}</h3>
                        <p className="text-lg text-primary mb-4">{t("Tailored Pricing")}</p>
                        <ul className="text-sm text-default-100 mb-6 space-y-2">
                            <li>{t("AI solutions designed for your business needs")}</li>
                            <li>{t("Enterprise-grade security and compliance")}</li>
                            <li>{t("Dedicated AI consultant for support and optimization")}</li>
                        </ul>
                        <a href="/contact" className="px-6 py-2 text-white bg-primary rounded-lg text-lg font-medium hover:bg-primary/80">
                            {t("Contact Us")}
                        </a>
                    </div>
                </div>
            </section>

            <section dir={language == 'ar'? 'rtl' : 'ltr'} id="contact" className="py-20">
                <div className="container text-center">
                    <h2 className="text-3xl font-medium capitalize text-white mb-4">{t("Contact Us")}</h2>
                    <p className="text-sm text-default-200 font-medium mb-10">{t("We’re here to answer your questions. Feel free to reach out to us!")}</p>
                    
                    <div className="max-w-2xl mx-auto text-center bg-default-950/40 backdrop-blur-3xl rounded-xl p-6">
                        {/* <h3 className="text-xl font-medium text-default-200 mb-4">{t("Get in Touch")}</h3> */}
                        <ul className="space-y-4">
                            <li className="text-default-300"><strong>Mobile (Whatsapp):</strong> <a target="_blank" href="https://wa.me/+905050102251">+90 505 010 22 51</a></li>
                            <li className="text-default-300"><strong>Email:</strong> info@ragminds.com</li>
                            <li className="text-default-300"><strong>Address:</strong> Istanbul / Turkey</li>
                        </ul>
                    </div>
                </div>
            </section>
        </div>
    );
}

export default Home;